import React, { useState, useEffect } from "react";
import axios from "axios";
import { Radar, Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { HiOutlineDownload } from "react-icons/hi";
import ReactCountryFlag from "react-country-flag";
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalChats, setTotalChats] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState(0);
  const [filteredChats, setFilteredChats] = useState(0);
  const [filteredMessages, setFilteredMessages] = useState(0);
  const [error, setError] = useState("");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [locationDateRange, setLocationDateRange] = useState([null, null]);
  const [locationStartDate, locationEndDate] = locationDateRange;

  const [locationCounts, setLocationCounts] = useState([]);
  const [expandedCountries, setExpandedCountries] = useState([]);

  // Toggle the expanded state of a country
  const toggleCountry = (country) => {
    setExpandedCountries((prev) => ({
      ...prev,
      [country]: !prev[country],
    }));
  };

  const getCountryCode = (countryName) => {
    const countryCodeMap = {
      Afghanistan: "AF",
      Albania: "AL",
      Algeria: "DZ",
      Andorra: "AD",
      Angola: "AO",
      Argentina: "AR",
      Armenia: "AM",
      Australia: "AU",
      Austria: "AT",
      Azerbaijan: "AZ",
      Bahamas: "BS",
      Bahrain: "BH",
      Bangladesh: "BD",
      Barbados: "BB",
      Belarus: "BY",
      Belgium: "BE",
      Belize: "BZ",
      Benin: "BJ",
      Bhutan: "BT",
      Bolivia: "BO",
      "Bosnia And Herzegovina": "BA",
      Botswana: "BW",
      Brazil: "BR",
      Brunei: "BN",
      Bulgaria: "BG",
      "Burkina Faso": "BF",
      Burundi: "BI",
      Cambodia: "KH",
      Cameroon: "CM",
      Canada: "CA",
      "Cape Verde": "CV",
      "Central African Republic": "CF",
      Chad: "TD",
      Chile: "CL",
      China: "CN",
      Colombia: "CO",
      Comoros: "KM",
      Congo: "CG",
      "Costa Rica": "CR",
      Croatia: "HR",
      Cuba: "CU",
      Cyprus: "CY",
      "Czech Republic": "CZ",
      Denmark: "DK",
      Djibouti: "DJ",
      Dominica: "DM",
      "Dominican Republic": "DO",
      "East Timor": "TL",
      Ecuador: "EC",
      Egypt: "EG",
      "El Salvador": "SV",
      "Equatorial Guinea": "GQ",
      Eritrea: "ER",
      Estonia: "EE",
      Eswatini: "SZ",
      Ethiopia: "ET",
      Fiji: "FJ",
      Finland: "FI",
      France: "FR",
      Gabon: "GA",
      Gambia: "GM",
      Georgia: "GE",
      Germany: "DE",
      Ghana: "GH",
      Greece: "GR",
      Grenada: "GD",
      Guatemala: "GT",
      Guinea: "GN",
      "Guinea-Bissau": "GW",
      Guyana: "GY",
      Haiti: "HT",
      Honduras: "HN",
      Hungary: "HU",
      Iceland: "IS",
      India: "IN",
      Indonesia: "ID",
      Iran: "IR",
      Iraq: "IQ",
      Ireland: "IE",
      Israel: "IL",
      Italy: "IT",
      Jamaica: "JM",
      Japan: "JP",
      Jordan: "JO",
      Kazakhstan: "KZ",
      Kenya: "KE",
      Kiribati: "KI",
      "North Korea": "KP",
      "South Korea": "KR",
      Kuwait: "KW",
      Kyrgyzstan: "KG",
      Laos: "LA",
      Latvia: "LV",
      Lebanon: "LB",
      Lesotho: "LS",
      Liberia: "LR",
      Libya: "LY",
      Liechtenstein: "LI",
      Lithuania: "LT",
      Luxembourg: "LU",
      Madagascar: "MG",
      Malawi: "MW",
      Malaysia: "MY",
      Maldives: "MV",
      Mali: "ML",
      Malta: "MT",
      "Marshall Islands": "MH",
      Mauritania: "MR",
      Mauritius: "MU",
      Mexico: "MX",
      Micronesia: "FM",
      Moldova: "MD",
      Monaco: "MC",
      Mongolia: "MN",
      Montenegro: "ME",
      Morocco: "MA",
      Mozambique: "MZ",
      Myanmar: "MM",
      Namibia: "NA",
      Nauru: "NR",
      Nepal: "NP",
      Netherlands: "NL",
      "New Zealand": "NZ",
      Nicaragua: "NI",
      Niger: "NE",
      Nigeria: "NG",
      Norway: "NO",
      Oman: "OM",
      Pakistan: "PK",
      Palau: "PW",
      Panama: "PA",
      "Papua New Guinea": "PG",
      Paraguay: "PY",
      Peru: "PE",
      Philippines: "PH",
      Poland: "PL",
      Portugal: "PT",
      Qatar: "QA",
      Romania: "RO",
      Russia: "RU",
      Rwanda: "RW",
      "Saint Kitts And Nevis": "KN",
      "Saint Lucia": "LC",
      "Saint Vincent And The Grenadines": "VC",
      Samoa: "WS",
      "San Marino": "SM",
      "Sao Tome And Principe": "ST",
      "Saudi Arabia": "SA",
      Senegal: "SN",
      Serbia: "RS",
      Seychelles: "SC",
      SierraLeone: "SL",
      Singapore: "SG",
      Slovakia: "SK",
      Slovenia: "SI",
      "Solomon Islands": "SB",
      Somalia: "SO",
      "South Africa": "ZA",
      Spain: "ES",
      "Sri Lanka": "LK",
      Sudan: "SD",
      Suriname: "SR",
      Sweden: "SE",
      Switzerland: "CH",
      Syria: "SY",
      Taiwan: "TW",
      Tajikistan: "TJ",
      Tanzania: "TZ",
      Thailand: "TH",
      Togo: "TG",
      Tonga: "TO",
      "Trinidad And Tobago": "TT",
      Tunisia: "TN",
      Turkey: "TR",
      Turkmenistan: "TM",
      "Turks and Caicos Islands": "TC",
      Tuvalu: "TV",
      Uganda: "UG",
      Ukraine: "UA",
      "United Arab Emirates": "AE",
      "United Kingdom": "GB",
      "United States": "US",
      Uruguay: "UY",
      Uzbekistan: "UZ",
      Vanuatu: "VU",
      "Vatican City": "VA",
      Venezuela: "VE",
      Vietnam: "VN",
      Yemen: "YE",
      Zambia: "ZM",
      Zimbabwe: "ZW",
    };
    return countryCodeMap[countryName] || "XX";
  };

  // Group location counts by country
  const groupedByCountry = locationCounts.reduce((acc, loc) => {
    // console.log(locationCounts, "locationCounts");
    const country = loc.country || "Denied Location";
    if (!acc[country]) {
      acc[country] = [];
    }
    acc[country].push(loc);
    return acc;
  }, {});

  const fetchAllData = async () => {
    try {
      const [usersResponse, chatsResponse, messagesResponse] =
        await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/total-users`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/total-chats`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/total-messages`),
        ]);
      setTotalUsers(usersResponse.data.totalUsers);
      setTotalChats(chatsResponse.data.totalChats);
      setTotalMessages(messagesResponse.data.totalMessages);

      // Additional logging to check state values
      console.log("Total Users:", usersResponse.data.totalUsers);
      console.log("Total Chats:", chatsResponse.data.totalChats);
      console.log("Total Messages:", messagesResponse.data.totalMessages);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Error fetching data.");
    }
  };

  const fetchDataByDateRange = async () => {
    try {
      if (!startDate || !endDate) {
        setFilteredUsers(totalUsers);
        setFilteredChats(totalChats);
        setFilteredMessages(totalMessages);
        setLocationCounts([]); // Reset location counts when no date range
        return;
      }

      const formattedStartDate = startDate.toLocaleDateString("en-CA");
      const formattedEndDate = endDate.toLocaleDateString("en-CA");
      console.log(formattedStartDate, "start");
      console.log(formattedEndDate, "end");

      const [usersResponse, chatsResponse, messagesResponse, locationResponse] =
        await Promise.all([
          axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/total-users?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
          ),
          axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/total-chats?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
          ),
          axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/total-messages?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
          ),
          axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/users-location-count?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
          ), // Updated API call for location counts
        ]);

      setFilteredUsers(usersResponse.data.totalUsers);
      setFilteredChats(chatsResponse.data.totalChats);
      setFilteredMessages(messagesResponse.data.totalMessages);
      setLocationCounts(locationResponse.data); // Set location counts based on response

      console.log(locationResponse.data, "jh");
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Error fetching data.");
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    fetchDataByDateRange();
  }, [startDate, endDate]);

  // Fetch user data by location
  const fetchLocationCounts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/users-location-count`
      );
      console.log(response);
      setLocationCounts(response.data);
    } catch (err) {
      console.error("Error fetching location counts:", err);
      setError("Error fetching location counts.");
    }
  };

  useEffect(() => {
    fetchLocationCounts();
  }, []);

  const generateCSV = () => {
    // Combine Analytics Data
    const analyticsData = [
      ["Metric", "Count"],
      ["Total Users", startDate && endDate ? filteredUsers : totalUsers],
      ["Total Chats", startDate && endDate ? filteredChats : totalChats],
      [
        "Total Messages",
        startDate && endDate ? filteredMessages : totalMessages,
      ],
      [], // Empty line to separate sections
    ];

    // Combine Location Counts Data
    const locationData = [
      ["Country", "City", "Count"],
      ...locationCounts.map((loc) => [
        loc.country || "Denied Location",
        loc.city || "No City",
        loc.count,
      ]),
    ];

    // Merge both data arrays into one
    const combinedData = [
      ["Analytics Data:"], // Title for analytics section
      ...analyticsData,
      ["Location Counts:"], // Title for location section
      ...locationData,
    ];

    // Convert to CSV content
    const csvContent = combinedData.map((e) => e.join(",")).join("\n");
    return new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  };

  const downloadCSV = () => {
    const blob = generateCSV();
    const url = URL.createObjectURL(blob);

    const formattedAnalyticsStartDate = startDate
      ? startDate.toISOString().split("T")[0]
      : "N/A";
    const formattedAnalyticsEndDate = endDate
      ? endDate.toISOString().split("T")[0]
      : "N/A";
    const formattedLocationStartDate = locationStartDate
      ? locationStartDate.toISOString().split("T")[0]
      : "N/A";
    const formattedLocationEndDate = locationEndDate
      ? locationEndDate.toISOString().split("T")[0]
      : "N/A";

    const filename =
      `combined_data_${formattedAnalyticsStartDate}_to_${formattedAnalyticsEndDate}_loc_${formattedLocationStartDate}_to_${formattedLocationEndDate}.csv`.replace(
        /:/g,
        "-"
      );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Fetch location counts whenever date range changes
  useEffect(() => {
    setLocationCounts([]);
  }, [locationStartDate, locationEndDate]);

  // Fetch location counts on initial load
  useEffect(() => {
    fetchLocationCounts();
  }, []);

  const barData = {
    labels: ["Total Users", "Total Chats", "Total Messages"],
    datasets: [
      {
        label: "Count",
        data: [
          startDate && endDate ? filteredUsers : totalUsers,
          startDate && endDate ? filteredChats : totalChats,
          startDate && endDate ? filteredMessages : totalMessages,
        ],
        backgroundColor: [
          "#3B82F6", // Tailwind blue-500
          "#22C55E", // Tailwind green-500
          "#A855F7", // Tailwind purple-500
        ],
        borderColor: [
          "#3B82F6", // Border color for Total Users (blue-500)
          "#22C55E", // Border color for Total Chats (green-500)
          "#A855F7", // Border color for Total Messages (purple-500)
        ],
        borderWidth: 2,
        barThickness: 40,
      },
    ],
  };

  // const radarData = {
  //     labels: ['Total Users', 'Total Chats', 'Total Messages'],
  //     datasets: [
  //         {
  //             label: 'Count',
  //             data: [
  //                 startDate && endDate ? filteredUsers : totalUsers,
  //                 startDate && endDate ? filteredChats : totalChats,
  //                 startDate && endDate ? filteredMessages : totalMessages,
  //             ],
  //             backgroundColor: 'rgba(153, 102, 255, 0.2)',  // Light purple fill
  //             borderColor: 'rgba(153, 102, 255, 1)',        // Darker purple border
  //             borderWidth: 1,
  //             fill: true,
  //         },
  //     ],
  // };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Total Users, Chats, and Messages (Bar Chart)",
      },
    },
  };

  // const radarOptions = {
  //     responsive: true,
  //     scales: {
  //         r: {
  //             suggestedMin: 0,
  //             suggestedMax: Math.max(totalUsers, totalChats, totalMessages) + 10, // Adjust for padding
  //         },
  //     },
  //     plugins: {
  //         legend: {
  //             position: 'top',
  //         },
  //         title: {
  //             display: true,
  //             text: 'Total Users, Chats, and Messages (Radar Chart)',
  //         },
  //     },
  // };

  return (
    <div className="p-2">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-3xl font-bold mb-2">Home</h1>
          <p className="text-gray-500">
            Welcome! Take a coffee and sit with your analysis...
          </p>
        </div>
        <div className="flex flex-col items-end">
          <div className="flex items-center mb-2">
            <p className="text-black text-sm font-semibold mr-2">
              Select Date Range
            </p>
            <button onClick={downloadCSV}>
              <HiOutlineDownload />
            </button>
          </div>
          <div className="flex space-x-4">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
              dateFormat="yyyy-MM-dd"
              className="p-2 border border-gray-300 rounded w-[200px] h-[30px] text-sm"
              placeholderText="Select a date range"
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <p className="text-xs text-gray-500 mt-1">
          Recommended: Refresh your web browser once you reach the page to
          ensure accurate data.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-8">
        <div className="p-4 border-l-4 border-blue-500 bg-white/10 backdrop-blur-lg shadow-lg rounded-md">
          <h2 className="text-2xl font-bold">
            {startDate && endDate ? filteredUsers : totalUsers}
          </h2>
          <p className="text-gray-500">Total Users</p>
        </div>
        <div className="p-4 border-l-4 border-green-500 bg-white/20 backdrop-blur-lg shadow-lg rounded-md">
          <h2 className="text-2xl font-bold">
            {startDate && endDate ? filteredChats : totalChats}
          </h2>
          <p className="text-gray-500">Total Chats</p>
        </div>
        <div className="p-4 border-l-4 border-purple-500 bg-white/20 backdrop-blur-lg shadow-lg rounded-md">
          <h2 className="text-2xl font-bold">
            {startDate && endDate ? filteredMessages : totalMessages}
          </h2>
          <p className="text-gray-500">Total Messages</p>
        </div>
      </div>

      {error && <p className="text-purple-500">{error}</p>}

      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="bg-white p-4 rounded-md shadow w-full lg:mb-0">
                    <Bar options={barOptions} data={barData} />
                </div>
                <div className="flex bg-white justify-center items-center p-4 rounded-md shadow w-full lg:mb-0 h-[400px]">
                    <div className='w-[450px] h-[450px] mt-16'>
                        <Radar options={radarOptions} data={radarData} />
                    </div>
                </div>
            </div> */}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-md shadow w-full lg:mb-0">
          <Bar options={barOptions} data={barData} />
        </div>

        {/* COUNTRY & City User Loaction Count */}
        <div className="flex flex-col bg-white p-4 rounded-md shadow w-full lg:mb-0 h-[400px]">
          <h3 className="text-xs text-gray-600 font-bold mb-4 text-center">
            Total Users Count by Location
          </h3>
          <div className="h-full overflow-y-auto">
            <table className="w-full text-left">
              <thead>
                <tr className="border-b-2 border-gray-300">
                  <th className="p-2 text-gray-500 font-medium">Country</th>
                  <th className="p-2 text-gray-500 font-medium">Count</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(groupedByCountry).map((country, index) => (
                  <React.Fragment key={index}>
                    {/* Country Row */}
                    <tr
                      className="border-b cursor-pointer"
                      onClick={() => toggleCountry(country)}
                    >
                      <td className="p-2 flex items-center">
                        {/* Country Flag */}
                        {country !== "Denied Location" && (
                          <ReactCountryFlag
                            countryCode={getCountryCode(country)}
                            svg
                            style={{
                              width: "20px",
                              height: "15px",
                              marginRight: "8px",
                            }}
                          />
                        )}
                        {country}
                      </td>
                      <td className="p-2">
                        {groupedByCountry[country].reduce(
                          (sum, loc) => sum + loc.count,
                          0
                        )}
                      </td>
                    </tr>
                    {/* City Rows (only visible if expanded) */}
                    {expandedCountries[country] &&
                      groupedByCountry[country].map((loc, idx) => (
                        <tr
                          key={`${country}-${idx}`}
                          className="border-b pl-4 border"
                        >
                          <td className="p-2 pl-4">
                            {loc.city || loc.state || "No City"}
                          </td>
                          <td className="p-2">{loc.count}</td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
